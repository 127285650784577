.ui-menu {
    background-color: #ededed;
    .ui-state-active {
        padding: 7px 10px;
        margin: auto;
        background-color: #f2f2f2;
        font-weight: 700;
    }
    .ui-menu-item-wrapper {
        padding: 7px 10px;
    }
}


.checkout-title {
    padding:0 0 15px 0;
    text-transform: uppercase;
    display: block;
    font-size: 1.25rem;
}

.checkout-destination {
    padding:10px 0;
    display: block;
    font-size: 1.25rem;
}

.checkout-sep {
    color:#fff;
    text-transform: uppercase;
    font-size: 1.25rem;
    padding:15px;
    display: block;
}
.checkout-box {
    height: 100%;
    padding: 20px;
    color:#fff;
    a {
        color:$pink;
    }
    &.bg-darkblue {
        a {
            color:#fff;
            font-weight: 600;
        }
    }
}

#calculation {
    table {
        td {
            padding: 10px 0 0 10px;
        }
        tr.seperator td {
            padding:10px 0 0 0;
            border-bottom: 1px solid #fff;
        }
    }
}


.report-order-box input[type=checkbox] {
    display:none;
}

input[type=checkbox] + label {
    .fa-square  {
        font-size:3rem;
        color:$pink;
    }
    .fa-check {
        display: none;
    }
}

input[type=checkbox]:checked + label {
    .fa-check {
        font-size:1.5rem;
        color:#fff;
        display: inline;
    }
}


div.header-top {
    text-align: right;
    background-color: $pink;
    height:28px;
    z-index:999;

    a {
        color:#fff;
        font-size: 0.875rem;
        margin: 0 5px;
    }
}
@media (min-width:1200px) {
    body.report {
        div.header-top {
            margin-left: 200px;
        }
    }
}
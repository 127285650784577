section.main {
    padding-bottom: 50px;
}
div.header-main {
    position: relative;
    img.coming-soon {
        z-index: 9999;
        position: absolute;
        left: 150px;
        top: 25px;
        transform: rotate(-14deg);
        max-width: 150px;
    }
}
@media (min-width:768px) {
    div.header-main {
        img.coming-soon {
            top: 100px;
            left: 220px;
        }
    }
}
@media (min-width:992px) {
    div.header-main {
        img.coming-soon {
            top: 30px;
            max-width:230px;
            left: 160px;

        }
    }
}
@media (min-width:1200px) {
    div.header-main {
        img.coming-soon {
            top: 25px;
            max-width:290px;
            left: 220px;

        }
    }
}

@media (min-width:768px) {
    div.header-main {
        min-height: 200px;

    }
}
@media (min-width:768px) {
    .npl {
        padding-left: 0;
    }
    .npr {
        padding-right: 0;
    }
}
@media (min-width:768px) {
    .pl-20 {
        padding-left: 5px;
    }
    .pr-20 {
        padding-right: 5px;
    }
    .pl-10 {
        padding-left: 5px;
    }
    .pr-10 {
        padding-right: 5px;
    }
}


@media (min-width:1200px) {
    .npl-lg {
        padding-left: 0;
    }
    .npr-lg {
        padding-right: 0;
    }
}

.logo {
    img {
        max-width: 170px;
        margin: 10px 0;
    }
}
@media (min-width:768px) {
    .logo {
        position:absolute;
        top:25px;
        img {
            margin: 0;
        }
    }
}
.login-form {
    text-align: right;
    max-width:550px;
    input {
        margin-bottom: 5px;
        display: block;
        width:100%;
        background-color:$blue;
        line-height: 50px;
        height: 50px;
        padding: 0 5px;
        text-align: center;
        &::-webkit-input-placeholder {
            color: #fff;
        }
        &[type="submit"] {
            background-color: $pink;
            &:hover {
               cursor: pointer;
                background-color: $darkpink;
                color:#fff;
            }
        }

    }
    .login-message {
        text-align: left;
        font-size: 12px;
        display: inline-block;
    }

}
@media (min-width:768px) {
    .login-form {
        position:absolute;
        top:25px;
        right:0px;
        input {
            display: inline;
            width:180px;
        }
    }

}
.intro {
    position:relative;
    strong{
        font-size: 1.625rem;
    }
    p {
        font-size: 1.25rem;
    }

}
div.info-box {
    padding: 30px 20px;
    background-color:#f2f2f2;

    h3 {
        font-weight:700;
        margin: 0;
        font-size: 1.375rem;
        color:$pink;
        text-transform:uppercase;
    }
    strong {
        line-height: 1.4em;
        font-size: 1.125rem;
        display:block;
        margin-top: 5px;
        color:$darkblue;
    }
    p {
        margin-top: 5px;
        font-size: 1.125rem;
        line-height: 1.6;
    }
    img {
        display: block;
        margin: 0 auto;
        max-width: 270px;
    }
}
.spacer {
    height:40px;
}
p.intro-text {
    font-size: 1.25rem !important;
}

section.search {
    background-color: $blue;
    padding:20px 0 40px 0;
    h3 {
        color:#fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.25rem;
    }
    h4.info {
        color:#fff;
        margin: 20px 0;
        font-weight: 700;
        font-size: 1rem;
        text-align: center;
        .fa {
            font-size: 2rem;

            margin-top: 20px;
        }
    }
    input {
        line-height: 60px;
        width:100%;
        display:inline-block;
        background-color: $lightblue;
        padding-left: 50px;
        font-size: 0.75rem;
        font-weight: 700;
        color:$pink;
        &::-webkit-input-placeholder {
            color: #fff;
        }
    }
    @media (min-width:768px) {
        input {
            line-height: 135px;
            height: 135px;
            font-size: 1.625rem;
        }
    }
    .search-box {
        height: 100%;
        background-color: $darkblue;
        color:#fff;
        padding: 25px;
    }

    .search-result {
        display:none;
        margin-top: 30px;
    }
}


section.newsletter {
    background-color: $blue;
    padding:40px 0;
    h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.75rem;
        color:#fff;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1.5em;
    }
    input {
        line-height: 30px;
        width:100%;
        display:inline-block;
        background-color: $lightblue;
        padding-left: 50px;
        font-size: 0.75rem;
        font-weight: 700;
        &::-webkit-input-placeholder {
            color: #fff;
        }
    }

    input {
        line-height: 60px;
        font-size: 1.625rem;
    }

    .search-box {
        height: 100%;
        background-color: $darkblue;
        color:#fff;
        padding: 25px;
    }

    .search-result {
        display:none;
        margin-top: 30px;
    }
}


section.headline {
    padding:50px 0 ;
    h3 {
        font-weight:700;
        margin: 0 0 0 38px;
        font-size: 1.3125rem;
        color:$pink;
        text-transform:uppercase;
    }
    p {margin-top: 10px;
       display: block;
       text-align: center;
       font-size: 1rem;
    }
}
section.info {
    background-color:#d3d3d3;
}
section.partner {
    padding-bottom:40px;
    .col {
        min-height: 200px;
        position: relative;
    }
    img{
        max-width: 207px;
        max-height: 91px;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        left: 50%;
    }
}

section.about {
    padding-bottom:40px;
    .col {
        min-height: 200px;
        position: relative;
    }
    img{
        max-width: 220px;
        max-height: 125px;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        left: 50%;
    }
}

.report-box {
    padding: 46px 38px;
    h3 {
        font-weight:700;
        margin: 0;
        font-size: 1.3125rem;
        color:$pink;
        text-transform:uppercase;
    }

    ul {
        margin-top: 30px;
        list-style-type: none;
        padding: 0;
        li {
            margin: 0 0 10px 0;
        }
    }
    strong {
        text-transform: uppercase;
    }

    p {
        font-size: 1.125rem;
    }
    .fa {
        color:$pink;
    }
}

.report-order-box{
    color:#fff;
    padding: 25px;
    strong.title {
        text-transform: uppercase;
    }
    label {
        font-weight: normal;
        cursor: pointer;
    }
}

section.report-teaser {
    background-color: #606060;
}
.teaser-box {
    color:#fff;
    padding: 46px 38px;
    h3 {
        color:#fff;
        font-weight:700;
        margin: 0;
        font-size: 1.3125rem;
        text-transform:uppercase;
    }
    p.teaser-intro {
        margin: 30px 0 40px 0;
        color:#fff;
        font-size: 1rem;
    }
    a.swipebox {
        display: block;
        position: relative;
        .icon {
            background-color: $pink;
            display: block;
            width: 65px;
            height:55px;
            position: absolute;
            right:-10px;
            top:30px;
            border:1px solid red;
            background-image: url('/img/icons/search.png');
            background-repeat: no-repeat;
            background-position: 15px 10px;
            background-size:  60%;
            border-radius: 10px 0 0 10px;
        }
    }
    img {
        width: 100%;
        max-width: 250px;
        margin-bottom: 15px;
    }
    ul {

        margin-top: 0px;
        list-style-type: none;
        padding: 0;
        li {
            margin: 0 0 20px 0;
        }
        strong {
            font-weight: 600;
            text-transform: uppercase;
        }
        p {
            font-size: 0.875rem;
        }
        .fa {
            color:$pink;
        }
    }

}
.content-page {
    padding-bottom:50px;
    a {
        color:$pink;

    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            margin-bottom: 10px;
        }
    }
}
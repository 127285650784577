footer {

    color:#fff;
    background-color: #262626;

    a {
        color:$pink;
    }
    .footer-box {
        padding: 50px 0;
    }
    .footer-logo {
        max-width: 200px;
    }
    input {

        line-height: 50px;
        height: 50px;
        width:100%;
        display:inline-block;
        background-color: $lightblue;
        padding-left: 50px;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom:10px;
        &::-webkit-input-placeholder {
            color: #fff;
        }
    }
}
section.sub-footer {
    text-align:center;


    padding: 20px 0;
    background-color: #111;
    a {
        color:#fff;
    }
}
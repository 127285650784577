$pink : #ea1e63;
$darkpink : #C2185B;
$blue: #16b7d3;
$lightblue:#a2e2ec;
$darkblue: #058db5;
$green: #64DD17; 
$lightgreen:#B2FF59;
$lightgrey:#d3d3d3;
$checkoutfooter:#052c33;
.bg-white {
    background-color: #fff;
}
.bg-grey {
    background-color: #f2f2f2;
}
.bg-grey-a {
    background-color: #31312f;
}
.bg-grey-b {
    background-color: #3e3e3e;
}
.bg-blue {
    background-color: $blue;
}
.bg-darkblue {
    background-color:$darkblue;
}
.bg-pink {
    background-color: $pink;
}
.bg-green {
    background-color: $green;
}
.bg-lightgreen {
    background-color: $lightgreen;
}
.bg-checkoutfooter {
    background-color: $checkoutfooter;
}
.bg-lightgrey {
    background-color: $lightgrey;
}
.error-message {
    font-size: 0.75rem;
    margin-left: 5px;
}
.form-error {
    //   border: 2px solid red !important;
    background-color: #D33C44 ;
    color:#fff;
}
.checkbox-error {

    color:#D33C44;
}
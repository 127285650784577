@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

@import "_colors.scss";
@import "_form.scss";
@import "_buttons.scss";
@import "_page.scss";
@import "_checkout.scss";
@import "_topnavigation.scss";
@import "_ui.scss";
@import "_footer.scss";

html {
    font-size: 16px;
}
body {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}
.alert {
    font-size: 80%;
    margin-top: 15px;
}


button, input,.btn{
    border:0;
    border-radius: 0;
    color:#fff;
}
input[type=submit] {
    text-transform: uppercase;
    text-align: center;
}
button,.btn, {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}
.form-control {
    border:0px;
}


.btn-order {
    margin-top: 35px;
    background-color: $pink;
    display: block;
    width:100%;
    line-height: 80px;
    font-size: 1.25rem;
    &:hover {
        background-color: $darkpink;
        color:#fff;
    }
}
.btn-order-small {
    line-height: 60px;
    width: 100%;
    display: inline-block;
    background-color: $pink;
    font-size: 1rem;
    &:hover {
        background-color: $darkpink;
        color:#fff;
    }
}

.btn-open-report{
    color:#fff;
    width: 100%;
    display: inline-block;
    line-height: 40px;
    width: 100%;
    max-width: 180px;
    display: inline-block;
    background-color: $pink;
    font-size: 1rem;
    border:4px solid $pink;
}
.btn-order-report,.btn-order-report:hover{
    color: $pink;
    width: 100%;
    display: inline-block;
    line-height: 40px;
    width: 100%;
    max-width: 180px;
    display: inline-block;
    background-color: #fff;
    font-size: 1rem;
    border:4px solid $pink;
}


.btn-search {
    background-image: url('/img/icons/search.png');
    background-repeat: no-repeat;
    background-position: 25px 16px;
    background-size:  38px 31px;
    line-height: 60px;
    width:100%;
    display:inline-block;
    background-color: $pink;
    font-size: 0.75rem;
    padding-left: 50px;
    &:hover {
        cursor:pointer;
        background-color: $darkpink;
    }
}
.btn-newsletter {
    line-height: 60px;
    width: 100%;
    display: inline-block;
    background-color: $pink !important;
    font-size: 1.625rem;
    &:hover {
        background-color: $darkpink;
        color:#fff;
    }
}
.btn-newsletter-footer {
    line-height: 50px;
    width: 100%;
    display: inline-block;
    background-color: $pink !important;
    font-size: 1.25rem;
    &:hover {
        background-color: $darkpink;
        color:#fff;
    }
}



@media (min-width:768px) {
    .btn-search {
        height: 135px;
        padding-left: 75px;
        line-height: 135px;
        background-position: 28px 35px;
        background-size:  76px 62px;
        font-size: 1.3125rem;
    }
}

.btn-buy {
    background-color: #ea1e63;
    display: block;
    width: 100%;
    line-height: 125px;
    font-size: 1.25rem;
    &:hover {
        background-color: $darkpink;
        color:#fff;
    }
}

.btn-open-report {
    color: #fff;
    width: 100%;
    display: inline-block;
    line-height: 40px;
    width: 100%;
    max-width: 180px;
    display: inline-block;
    background-color: $pink;
    font-size: 1rem;
    border: 4px solid #ea1e63;
    &:hover,&:focus {
        background-color: $darkpink;
        border: 4px solid $darkpink;
        color:#fff;
    }
}


@-webkit-keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}
@-moz-keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}
@-o-keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}
@keyframes ld {
    0%   { transform: rotate(0deg) scale(1); }
    50%  { transform: rotate(180deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
}

.m-progress {
    position: relative;
    opacity: .8;
    color: transparent !important;
    text-shadow: none !important;
}

.m-progress:hover,
.m-progress:active,
.m-progress:focus {
    cursor: default;
    color: transparent;
    outline: none !important;
    box-shadow: none;
}

.m-progress:before {
    content: '';

    display: inline-block;

    position: absolute;
    background: transparent;
    border: 1px solid #fff;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;

    box-sizing: border-box;

    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;

    width: 24px;
    height: 24px;

    -webkit-animation: ld 1s ease-in-out infinite;
    -moz-animation:    ld 1s ease-in-out infinite;
    -o-animation:      ld 1s ease-in-out infinite;
    animation:         ld 1s ease-in-out infinite;
}

.btn-default.m-progress:before {
    border-left-color: #333333;
    border-right-color: #333333;
}